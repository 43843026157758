import React, { useState, useRef, useEffect } from "react";
import "./contact-us.css";
import NavBar from "../components/navbar";
//import vote from "../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../components/footerbar";
import Arrow from "../template/ScrollToTopButton";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const setScrollY = useState(0)[1];
  const mainRef = useRef(null);

  const handleClick = () => {
    setScrollY(100);
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Lalan Group Sri Lanka</title>
        <meta name="title" content="Contact Us | Lalan Group Sri Lanka" />
        <meta
          name="description"
          content="Get in touch with our team for more information. "
        />
        <meta name="keywords" content="Contact Us" />
        <link rel="canonical" href="https://www.lalangroup.com/contact-us/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Contact Us - Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          property="og:url"
          content="https://www.lalangroup.com/contact-us/"
        />
        <meta
          property="og:site_name"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
      </Helmet>
      <div className="nav-color-contact-us"></div> {/* NavBar color */}
      <NavBar />
      <div style={{ position: "relative" }}>
        <div className="fullpage-video-set">
          <img
            className="background-video-set"
            src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/contact-us/top-banner.jpg"
            alt="background-video"
          />
          {/* Media query for screens up to 1000px */}
          <style>
            {`@media (max-width: 1030px) {
              .background-video-set {
                content: url(${"https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/contact-us/contact-us2.jpg"});
              }
            }`}
          </style>
          {/* Media query for screens up to 600px */}
          <style>
            {`@media (max-width: 820px) {
              .background-video-set {
                content: url(${"https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/contact-us/contact-us3.jpg"});
              }
            }`}
          </style>
          <div className="video-overlay">
            <p>
              <div className="optimanormal">
                Contact Us
                <h1 style={{ color: "transparent", marginTop: "-150px" }}>
                  Contact Us
                </h1>
              </div>
            </p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="mouse-color-contact">
            <img
              onClick={handleClick}
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
              alt="button"
              className="mouse-button"
              style={{ height: "90px", zIndex: "10" }}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#030017", opacity: "0.92" }}>
        &nbsp;
        <div ref={mainRef}>
          {/* Main content */}
          <div
            style={{
              marginLeft: "25px",
              marginRight: "10px",
              marginTop: "150px",
            }}
          >
            <div className="containerT">
              {/** PC, Laptop view */}
              <table className="desktop-table">
                <tbody>
                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Corporate Head Office</h2>
                          <p className="containerT-h3">
                            995B, Zone A, Export Processing Zone, Biyagama,
                            Malwana, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 11 431 1200</p>
                          <p className="containerT-h3">
                            (+94) 11 431 1222
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Gloves</h2>
                          <p className="containerT-h3">
                            95B, Zone A, Export Processing Zone, Biyagama,
                            Malwana, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 11 431 1200</p>
                          <p className="containerT-h3">
                            (+94) 11 431 1222
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Renewable Energy</h2>
                          <p className="containerT-h3">
                            Lalan Energy Solutions (Pvt) Ltd / Lalan
                            Unagahadeniya Complex
                          </p>
                          <p className="containerT-h3">
                            Kamburagalllawatta, Unagahadeniya,
                          </p>
                          <p className="containerT-h3">
                            Ruggahawila, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 33 228 1919</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Leisure</h2>
                          <p className="containerT-h3">
                            81A, Temple road, Nawala Sri Lanka
                          </p>
                          <p className="containerT-h3">G(+94) 77 2105 494</p>
                          <p className="containerT-h3">
                            <a
                              style={{ color: "white" }}
                              href="https://www.lalanleisure.com/"
                            >
                              www.lalanleisure.com
                            </a>
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Engineering</h2>
                          <p className="containerT-h3">
                            344, Grandpass Road, Colombo 14, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) (0)11 4614211</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick(
                                "customercare.lepl@lalangroup.com"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            customercare.lepl@lalangroup.com
                          </p>{" "}
                          <br />
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Packaging</h2>
                          <p className="containerT-h3">
                            81 A, Temple Road, Sri Jayawardenepura Kotte, Sri
                            Lanka.
                          </p>
                          <p className="containerT-h3">
                            344, Grandpass Road, Colombo 14, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 011 4 347 700</p>
                          <p className="containerT-h3">
                            (+94) 011 4 612 106/102
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info.packaging@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info.packaging@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Contract Manufacturing</h2>
                          <p className="containerT-h3">Lalan Warana Complex</p>
                          <p className="containerT-h3">
                            Gorakadeniya Road, Uduthuththiripitiya, Yakkala.
                          </p>
                          <p className="containerT-h3">(+94) 71 456 5610</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info.cm@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info.cm@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <h2>Plantation</h2>
                          <p className="containerT-h3">
                            54, Kirulapane Avenue, Colombo 06, Sri Lanka
                          </p>
                          <p className="containerT-h3">(+94) (0)11 574 4555</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* BestWeb - start */}
                        {/* <div>
                          <a
                            href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                            target="_blank"
                          >
                            <img
                              className="logopic"
                              src={vote}
                              alt="logo"
                              style={{ marginTop: "60px", marginLeft: "100px" }}
                            />
                          </a>
                        </div> */}
                        {/* BestWeb - end */}

                        {/* TopWeb - start */}
                        <div>
                          <a
                            href="https://topweb.lk/may2024/lalangroup"
                            target="_blank"
                          >
                            <img
                              style={{ marginTop: "50px", marginLeft: "30px" }}
                              className="logopic-2"
                              src={TopWeb}
                              alt="logo"
                            />
                          </a>
                        </div>
                        {/* TopWeb - end */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/** width 1000 */}
              <table className="tab-table">
                <tbody>
                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Corporate Head Office</p>
                          <p className="containerT-h3">
                            995B, Zone A, Export Processing Zone, Biyagama,
                            Malwana, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 11 431 1200</p>
                          <p className="containerT-h3">
                            (+94) 11 431 1222
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Gloves</p>
                          <p className="containerT-h3">
                            95B, Zone A, Export Processing Zone, Biyagama,
                            Malwana, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 11 431 1200</p>
                          <p className="containerT-h3">
                            (+94) 11 431 1222
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Renewable Energy</p>
                          <p className="containerT-h3">
                            Lalan Energy Solutions (Pvt) Ltd / Lalan
                            Unagahadeniya Complex
                          </p>
                          <p className="containerT-h3">
                            Kamburagalllawatta, Unagahadeniya,
                          </p>
                          <p className="containerT-h3">
                            Ruggahawila, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 33 228 1919</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Leisure</p>
                          <p className="containerT-h3">
                            81A, Temple road, Nawala Sri Lanka
                          </p>
                          <p className="containerT-h3">G(+94) 77 2105 494</p>
                          <p className="containerT-h3">
                            <a
                              style={{ color: "white" }}
                              href="https://www.lalanleisure.com/"
                            >
                              www.lalanleisure.com
                            </a>
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Engineering</p>
                          <p className="containerT-h3">
                            344, Grandpass Road, Colombo 14, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) (0)11 4614211</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick(
                                "customercare.lepl@lalangroup.com"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            customercare.lepl@lalangroup.com
                          </p>{" "}
                          <br />
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Packaging</p>
                          <p className="containerT-h3">
                            81 A, Temple Road, Sri Jayawardenepura Kotte, Sri
                            Lanka.
                          </p>
                          <p className="containerT-h3">
                            344, Grandpass Road, Colombo 14, Sri Lanka.
                          </p>
                          <p className="containerT-h3">(+94) 011 4 347 700</p>
                          <p className="containerT-h3">
                            (+94) 011 4 612 106/102
                          </p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info.packaging@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info.packaging@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">
                            Contract Manufacturing
                          </p>
                          <p className="containerT-h3">Lalan Warana Complex</p>
                          <p className="containerT-h3">
                            Gorakadeniya Road, Uduthuththiripitiya, Yakkala.
                          </p>
                          <p className="containerT-h3">(+94) 71 456 5610</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info.cm@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info.cm@lalangroup.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card card-trns contact-blok-br-gradient">
                        <div class="card-body">
                          <p className="containerT-h2">Plantation</p>
                          <p className="containerT-h3">
                            54, Kirulapane Avenue, Colombo 06, Sri Lanka
                          </p>
                          <p className="containerT-h3">(+94) (0)11 574 4555</p>{" "}
                          <br />
                          <p
                            className="containerT-h3"
                            onClick={() =>
                              handleEmailClick("info@lalangroup.com")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            info@lalangroup.com
                          </p>{" "}
                          <br />
                          <br />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* BestWeb - start */}
                        {/* <div>
                          <a
                            href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                            target="_blank"
                          >
                            <img
                              className="logopic"
                              src={vote}
                              alt="logo"
                              style={{ marginTop: "55px", marginLeft: "50px" }}
                            />
                          </a>
                        </div> */}
                        {/* BestWeb - end */}

                        {/* TopWeb - start */}
                        <div>
                          <a
                            href="https://topweb.lk/may2024/lalangroup"
                            target="_blank"
                          >
                            <img
                              style={{ marginTop: "50px", marginLeft: "30px" }}
                              className="logopic-2"
                              src={TopWeb}
                              alt="logo"
                            />
                          </a>
                        </div>
                        {/* TopWeb - end */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/** Mobile view */}
              <div className="mobile-table">
                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Corporate Head Office</p>
                    <p className="containerT-h3">
                      995B, Zone A, Export Processing Zone, Biyagama, Malwana,
                      Sri Lanka.
                    </p>
                    <p className="containerT-h3">(+94) 11 431 1200</p>
                    <p className="containerT-h3">(+94) 11 431 1222</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info@lalangroup.com
                    </p>{" "}
                    <br />
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Gloves</p>
                    <p className="containerT-h3">
                      95B, Zone A, Export Processing Zone, Biyagama, Malwana,
                      Sri Lanka.
                    </p>
                    <p className="containerT-h3">(+94) 11 431 1200</p>
                    <p className="containerT-h3">(+94) 11 431 1222</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info@lalangroup.com
                    </p>{" "}
                    <br />
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Renewable Energy</p>
                    <p className="containerT-h3">
                      Lalan Energy Solutions (Pvt) Ltd / Lalan Unagahadeniya
                      Complex
                    </p>
                    <p className="containerT-h3">
                      Kamburagalllawatta, Unagahadeniya,
                    </p>
                    <p className="containerT-h3">Ruggahawila, Sri Lanka.</p>
                    <p className="containerT-h3">(+94) 33 228 1919</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info@lalangroup.com
                    </p>
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Leisure</p>
                    <p className="containerT-h3">
                      81A, Temple road, Nawala Sri Lanka
                    </p>
                    <p className="containerT-h3">G(+94) 77 2105 494</p>
                    <p className="containerT-h3">
                      <a
                        style={{ color: "white" }}
                        href="https://www.lalanleisure.com/"
                      >
                        www.lalanleisure.com
                      </a>
                    </p>{" "}
                    <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info@lalangroup.com
                    </p>{" "}
                    <br />
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Engineering</p>
                    <p className="containerT-h3">
                      344, Grandpass Road, Colombo 14, Sri Lanka.
                    </p>
                    <p className="containerT-h3">(+94) (0)11 4614211</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() =>
                        handleEmailClick("customercare.lepl@lalangroup.com")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      customercare.lepl@lalangroup.com
                    </p>{" "}
                    <br />
                    <br />
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Packaging</p>
                    <p className="containerT-h3">
                      81 A, Temple Road, Sri Jayawardenepura Kotte, Sri Lanka.
                    </p>
                    <p className="containerT-h3">
                      344, Grandpass Road, Colombo 14, Sri Lanka.
                    </p>
                    <p className="containerT-h3">(+94) 011 4 347 700</p>
                    <p className="containerT-h3">
                      (+94) 011 4 612 106/102
                    </p>{" "}
                    <br />
                    <p
                      className="containerT-h3"
                      onClick={() =>
                        handleEmailClick("info.packaging@lalangroup.com")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      info.packaging@lalangroup.com
                    </p>
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Contract Manufacturing</p>
                    <p className="containerT-h3">Lalan Warana Complex</p>
                    <p className="containerT-h3">
                      Gorakadeniya Road, Uduthuththiripitiya, Yakkala.
                    </p>
                    <p className="containerT-h3">(+94) 71 456 5610</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info.cm@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info.cm@lalangroup.com
                    </p>
                  </div>
                </div>

                <div class="card card-trns contact-blok-br-gradient">
                  <div class="card-body">
                    <p className="containerT-h2">Plantation</p>
                    <p className="containerT-h3">
                      54, Kirulapane Avenue, Colombo 06, Sri Lanka
                    </p>
                    <p className="containerT-h3">(+94) (0)11 574 4555</p> <br />
                    <p
                      className="containerT-h3"
                      onClick={() => handleEmailClick("info@lalangroup.com")}
                      style={{ cursor: "pointer" }}
                    >
                      info@lalangroup.com
                    </p>{" "}
                    <br />
                    <br />
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* BestWeb - start */}
                  {/* <div>
                    <a
                      href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                      target="_blank"
                    >
                      <img
                        className="logopic"
                        src={vote}
                        alt="logo"
                        style={{ marginTop: "55px", marginLeft: "20px" }}
                      />
                    </a>
                  </div> */}
                  {/* BestWeb - end */}

                  {/* TopWeb - start */}
                  <div>
                    <a
                      href="https://topweb.lk/may2024/lalangroup"
                      target="_blank"
                    >
                      <img
                        style={{ marginTop: "50px", marginLeft: "30px" }}
                        className="logopic-2"
                        src={TopWeb}
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* TopWeb - end */}
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#030017", opacity: "0.92" }}>
        <br />
        <br />
        <Footer3CS />
      </div>
      <Arrow />
    </div>
  );
};

export default ContactUs;
